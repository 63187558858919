<script>
    import { VueDraggableNext } from 'vue-draggable-next'
    import "@vueform/multiselect/themes/default.css";
    import { overlay } from "@/state/helpers";
    import "flatpickr/dist/flatpickr.css";
    import Layout from "../../../layouts/main.vue";
    import PageHeader from "@/components/page-header";
    import appConfig from "../../../../app.config";
    import {getEntity, updateEntity, saveEntity, viewEntity, callUrl} from '@/helpers/api-apolo';
    import {saError, saSuccess} from '@/helpers/global-functions';
    import {generateId} from "@/helpers/helper-functions";

    export default {
        page: {
            title: "Pipeline",
            meta: [{
                name: "description",
                content: appConfig.description
            }],
        },
        components: {
            Layout,
            PageHeader,
            draggable: VueDraggableNext,
        },
        data() {
            return {
                title: "Pipeline",
                items: [{
                        text: "CRM",
                        href: "/",
                    },
                    {
                        text: "Pipelines",
                        active: true,
                    },
                ],
                users: [],
                value: 'created_at',
                date: null,
                timeConfig: {
                    enableTime: false,
                    dateFormat: "Y-m-d",
                },
                phases: [],
                dataCreate: {
                  name: '',
                  is_active: true,
                  is_default: false,
                  phases: [],
                  attendants: [],
                  automatic_distribution: true,
                  attendants_only_see_your_own_businesses: true,
                  attendants_only_see_your_own_chats: true,
                  must_have_product_to_give_gain: true
                },
                enabled: true,
                dragging: false,
                changed: {},
                query: '',
                showAttendants: false
            };
        },
        methods: {
          ...overlay,
          changeStatus (data) {
            if (data.added) {
              this.changed = data.added.element;
            }
          },
          async changePhase(phase) {
            let oldPhase = JSON.parse(JSON.stringify(phase));
            let newPhase = JSON.parse(JSON.stringify(this.changed));
            for (let i = 0; i < this.phases.phases.length; i++) {
              if (this.phases.phases[i].id === phase.id) {
                for (let j = 0; j < phase.dealsData.length; j++) {
                  if (phase.dealsData[j].order !== newPhase.order) {
                    phase.dealsData[j].order = newPhase.order;
                    oldPhase = JSON.parse(JSON.stringify(phase.dealsData[j]));
                    this.phases.phases[i].dealsData.splice(j, 1);
                  } else {
                    phase.dealsData[j].order = oldPhase.order;
                  }
                }
                break;
              }
            }
            for (let i = 0; i < this.phases.phases.length; i++) {
              if (this.phases.phases[i].order === oldPhase.order) {
                this.phases.phases[i].dealsData.push(oldPhase);
              }
            }
          },
          async formatDefaultPhases() {
            let obj = {phases: []};
            obj.phases.push({"id": 'new_' + generateId(), order: 0, dealsData: [{"name": "Qualificação", "order": 0, "sla_in_days": 1, "probability": 40, "is_active": true}], "filters": []});
            obj.phases.push({"id": 'new_' + generateId(), order: 1, dealsData: [{"name": "Contato Realizado", "order": 1, "sla_in_days": 1, "probability": 25, "is_active": true}], "filters": []});
            obj.phases.push({"id": 'new_' + generateId(), order: 2, dealsData: [{"name": "Demostração Agendada", "order": 2, "sla_in_days": 1, "probability": 20, "is_active": true}], "filters": []});
            obj.phases.push({"id": 'new_' + generateId(), order: 3, dealsData: [{"name": "Proposta Efetuada", "order": 3, "sla_in_days": 1, "probability": 10, "is_active": true}], "filters": []});
            obj.phases.push({"id": 'new_' + generateId(), order: 4, dealsData: [{"name": "Negociação Iniciada", "order": 4, "sla_in_days": 1, "probability": 5, "is_active": true}], "filters": []});
            return obj;
          },
          async formatPhases(data) {
            let obj = {phases: []};
            for (let i = 0; i < data.length; i++) {
              obj.phases.push({
                id: data[i].id,
                order: data[i].order,
                filters: data[i].filters ?? [],
                dealsData: [{
                  name: data[i].name,
                  order: data[i].order,
                  sla_in_days: data[i].sla_in_days,
                  probability: data[i].probability,
                  is_active: data[i].is_active
                }]
              });
            }
            return obj;
          },
          async formatDefaultPipeline() {
            let obj = {};
            obj.name = '';
            obj.automatic_distribution = true;
            obj.is_default = false;
            obj.is_active = true;
            obj.attendants_only_see_your_own_businesses = true;
            obj.attendants_only_see_your_own_chats = true;
            obj.must_have_product_to_give_gain = true;
            obj.phases = [];
            obj.attendants = [];
            obj.products = [];
            return obj;
          },
          async addPhase(phase) {
            let obj = {
              order: 0,
              "name": "Nova Fase",
              "sla_in_days": 1,
              "probability": 10,
              "is_active": true,
              filters: []
            };
            let index = 0;
            for (let i = 0; i < this.phases.phases.length; i++) {
              if (this.phases.phases[i].order > phase.order) {
                index = i;
                break;
              }
            }
            if (phase.order === this.phases.phases.length - 1) {
              index = this.phases.phases.length;
              obj.order = index;
              this.phases.phases.push({"id": 'new_' + generateId(), order: index, dealsData: [obj], filters: []});
            } else {
              obj.order = index;
              this.phases.phases.splice(index, 0, {"id": 'new_' + generateId(), order: index, dealsData: [{name: obj.name, order: obj.order, sla_in_days: obj.sla_in_days, probability: obj.probability, is_active: obj.is_active}], filters: []});
            }
            for (let i = 0; i < this.phases.phases.length; i++) {
              this.phases.phases[i].order = i;
              this.phases.phases[i].dealsData[0].order = i;
            }
          },
          async getUsers() {
            try {
              getEntity('user')
                .then(async (data) => {
                  this.users = [];
                  if (data?.length > 0) {
                    for (let i = 0; i < data.length; i++) {
                      let obj = data[i];
                      if (!obj.avatar) {
                        obj.avatar = require('@/assets/images/users/user-dummy-img.jpg');
                      }
                      obj.value = obj.id;
                      // validate if exists in this.dataCreate.attendants looking for user_id as obj.id; if exists, set obj.pipelineAttendant = true;
                      let index = this.dataCreate.attendants.findIndex((item) => item.user_id === obj.id);
                      obj.pipelineAttendant = index > -1;
                      obj.isLoading = false;
                      this.users.push(obj);
                    }
                  }
                });
            } catch (error) {
              saError(error.message);
            }
          },
          async deletePhase(phase) {
            let phase_id = this.phases.phases[phase.order].id;
            if (phase_id.includes('new_') === false) {
              try {
                this.changeShowOverlay({show: true});
                await callUrl({}, '/pipeline/' + this.dataCreate.id + '/phase/' + phase_id, 'delete');
                this.changeShowOverlay({show: false});
              } catch (error) {
                saError(error.message)
              }
            }
            this.phases.phases.splice(phase.order, 1);
            for (let i = 0; i < this.phases.phases.length; i++) {
              this.phases.phases[i].order = i;
              this.phases.phases[i].dealsData[0].order = i;
            }
          },
          async preparePhases() {
            let phases = [];
            for (let i = 0; i < this.phases.phases.length; i++) {
              let obj = JSON.parse(JSON.stringify(this.phases.phases[i].dealsData[0]));
              obj.filters = [...this.phases.phases[i].filters];
              obj.id = this.phases.phases[i].id;
              if (obj.id.includes('new_')) {
                delete (obj.id);
                if (this.dataCreate.id)
                {
                  await this.createPhase(obj);
                }
              } else {
                await this.updatePhase(obj);
              }
              phases.push(obj);
            }
            return phases;
          },
          async savePipeline() {
            this.changeShowOverlay({show: true});
            let saveBody = JSON.parse(JSON.stringify(this.dataCreate));
            if (!saveBody.automatic_distribution) {
              saveBody.distribution_strategy = undefined;
            }
            saveBody.phases = await this.preparePhases();
            try {
              let result = {}
              if (saveBody.id) {
                result = await updateEntity(saveBody, 'pipeline')
              } else {
                result = await saveEntity(saveBody, 'pipeline')
              }
              this.changeShowOverlay({show: false});
              if (result.id) {
                saSuccess("Pipeline salvo com Sucesso!")
                this.cancel();
              }
            } catch (error) {
              this.changeShowOverlay({show: false});
              saError(error.message)
            }
          },
          cancel() {
            this.$router.push('/apps/crm-pipelines');
          },
          validateField(field, type = 'text') {
            if (type === 'integer' && field >= 0) {
              return {status: true, msg: ''};
            }

            let valid = !(!field || field.length === 0);
            if (!valid) {
              return {status: false, msg: 'Campo Obrigatório'};
            }
            return {status: true, msg: ''}
          },
          async formatPipeline(data) {
            this.dataCreate = data;
            this.phases = await this.formatPhases(data.phases);
          },
          async getPipeline(id) {
            try {
              const dataM = await viewEntity(id, 'pipeline');
              await this.formatPipeline(dataM)
            } catch (error) {
              saError(error.message)
            }
          },
          async createPhase(obj) {
            try {
              await callUrl(obj, '/pipeline/' + this.dataCreate.id + '/phase', 'post');
            } catch (error) {
              saError(error.message)
            }
          },
          async updatePhase(obj) {
            try {
              await callUrl(obj, '/pipeline/' + this.dataCreate.id + '/phase/' + obj.id, 'put');
            } catch (error) {
              saError(error.message)
            }
          },
          async removeAttendant(user) {
            try {
              user.isLoading = true;
              await callUrl({}, '/pipeline/' + this.dataCreate.id + '/attendant/' + user.id, 'delete');
              // find user in array users and switch pipelineAttendant to false
              for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].id === user.id) {
                  this.users[i].pipelineAttendant = false;
                  user.isLoading = false;
                  break;
                }
              }
            } catch (error) {
              saError(error.message)
            }
          },
          async addAttendant(user) {
            try {
              user.isLoading = true;
              await callUrl({}, '/pipeline/' + this.dataCreate.id + '/attendant/' + user.id, 'post');
              // find user in array users and switch pipelineAttendant to true
              for (let i = 0; i < this.users.length; i++) {
                if (this.users[i].id === user.id) {
                  this.users[i].pipelineAttendant = true;
                  user.isLoading = false;
                  break;
                }
              }
            } catch (error) {
              saError(error.message)
            }
          },
          changeFilterPhase (phaseId, filter) {
            const phase = this.phases.phases.filter(phase => phase.id === phaseId)[0]
            if (!phase.filters) {
              phase.filters = [];
            }
            if (phase.filters.includes(filter)) {
              phase.filters = phase.filters.filter(f => f !== filter);
              return;
            }
            phase.filters.push(filter);
          }
        },
        async mounted() {
          if (this.$route.params.id) {
            this.changeShowOverlay({show: true});
            await this.getPipeline(this.$route.params.id);
            this.changeShowOverlay({show: false});
          } else {
            this.dataCreate = await this.formatDefaultPipeline();
            this.phases = await this.formatDefaultPhases()
          }
          await this.getUsers();
        }
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <div class="row g-3">
                    <div class="col-md-5  col-auto">
                      <div class="row flex-column">
                        <div class="col-auto">
                          <label for="pipelineName" class="form-label">Nome</label>
                          <input type="text" :class="['form-control', validateField(dataCreate.name).status ? 'is-valid' : 'is-invalid']" id="pipelineName" v-model="dataCreate.name" placeholder="Digite o Nome" required>
                          <div class="invalid-feedback" v-show="!validateField(dataCreate.name).status">{{validateField(dataCreate.title).msg}}</div>
                        </div>
                        <div class="col-auto mt-3">
                          <label>Tempo de expiração por inatividade:&nbsp;&nbsp;&nbsp;</label>
                          <div class="input-step">
                            <button type="button" class="minus" @click="dataCreate.inactivity_expiration_time_in_minutes = parseInt(dataCreate.inactivity_expiration_time_in_minutes, 10) >= 1 ? dataCreate.inactivity_expiration_time_in_minutes - 1 : 0">-</button>
                            <input
                              type="text"
                              v-model="dataCreate.inactivity_expiration_time_in_minutes"
                              class="product-quantity"
                              min="0"
                              readonly
                            />
                            <button type="button" class="plus" @click="dataCreate.inactivity_expiration_time_in_minutes++">+</button>
                          </div>
                          <i class="mdi mdi-progress-question ms-2 fs-4" v-b-tooltip.hover title="Valor em minutos. Durante este período, se um atendimento não tiver alguma interação via chat com o lead, o atendimento será expirado e poderá ser redistribuído a um novo atendente. Caso o valor seja zero signifca que para este pipeline os atendimentos não expiram."></i>
                        </div>
                        <div v-show="dataCreate.automatic_distribution" class="col-auto mt-3">
                          <div class="row">
                            <label class="form-label">Estratégia distribuição</label>
                          </div>
                          <div>
                            <input v-model="dataCreate.distribution_strategy" class="form-check-input me-1" type="radio" name="distributionStrategy" id="distributionStrategyRoundRobin" value="round-robin">
                            <label class="form-check-label me-3" for="distributionStrategyRoundRobin">Round Robin</label>
                            <input v-model="dataCreate.distribution_strategy" class="form-check-input me-1" type="radio" name="distributionStrategy" id="distributionStrategyOnline" value="online">
                            <label class="form-check-label" for="distributionStrategyOnline">Atendentes Online</label>
                          </div>
                          <div class="form-check form-switch mt-2">
                            <input class="form-check-input" v-model="dataCreate.redistribute_for_inactivity" type="checkbox" role="switch" id="redistribute_for_inactivity">
                            <label class="form-check-label" for="redistribute_for_inactivity">Redistribuir negócios quando atendimentos inativos?</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-auto">
                        <div class="form-check form-switch">
                            <input class="form-check-input" v-model="dataCreate.is_default" type="checkbox" role="switch" id="rd_is_default">
                            <label class="form-check-label" for="rd_is_default">Definir como Padrão?</label>
                        </div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" v-model="dataCreate.automatic_distribution" type="checkbox" role="switch" id="rd_automatic_distribution">
                            <label class="form-check-label" for="rd_automatic_distribution">Distribuição Automática?</label>
                        </div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" v-model="dataCreate.attendants_only_see_your_own_businesses" type="checkbox" role="switch" id="rd_attendants_only_see_your_own_businesses">
                            <label class="form-check-label" for="rd_attendants_only_see_your_own_businesses">Atendente visualiza somente seus Negócios?</label>
                        </div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" v-model="dataCreate.attendants_only_see_your_own_chats" type="checkbox" role="switch" id="rd_attendants_only_see_your_own_chats">
                            <label class="form-check-label" for="rd_attendants_only_see_your_own_chats">Atendente visualiza somente seus Chats?</label>
                        </div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" v-model="dataCreate.must_have_product_to_give_gain" type="checkbox" role="switch" id="rd_must_have_product_to_give_gain">
                            <label class="form-check-label" for="rd_must_have_product_to_give_gain">Produto obrigatório para o Ganho?</label>
                        </div>
                    </div>
                    <div class="col-md-3 col-auto" style="display: flex;align-items: center;justify-content: flex-end;">
                        <b-button variant="outline-info" class="btn-icon waves-effect waves-light" @click.prevent="showAttendants = true">
                          <i class="bx bx-user-plus" style="font-size: 20px"></i>
                        </b-button>
                        <b-button variant="outline-dark" class="waves-effect waves-light" style="margin-left: 10px" @click.prevent="cancel">
                          Cancelar
                        </b-button>
                        <b-button variant="success" class="waves-effect waves-light" style="margin-left: 10px" @click.prevent="savePipeline">
                          Salvar
                        </b-button>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </div>
        <!--end card-->

        <div class="tasks-board mb-3" id="kanbanboard" style="height: 60vh !important; min-height: 250px !important;">
            <div class="tasks-list" v-for="(stage, key) in phases.phases" :key="'stage-' + key">
                <div data-simplebar class="tasks-wrapper px-3 mx-n3" style="max-height: 100% !important;height: 100% !important;">
                    <div :id="'#'+stage.id">
                        <b-button pill variant="outline-dark" class="btn-icon waves-effect waves-light bg-secondary" style="position: absolute;z-index: 999;top: 0;right: 0;border-color: #3577f1 !important" @click.prevent="addPhase(stage)"><i class="bx bx-plus fs-1"></i></b-button>
                        <draggable :list="stage.dealsData" class="dragArea" :group="{ name: 'order' }" @add="changePhase(stage)" @change="changeStatus">
                            <div class="card tasks-box" v-for="(piepeline, index) of stage.dealsData" :key="index">
                                <div class="card-body">
                                    <div class="d-flex mb-4">
                                        <h6 class="fs-14 text-uppercase fw-semibold mb-0">{{piepeline.name}}</h6>
                                    </div>
                                    <div class="row">
                                      <div class="col-12 mb-3">
                                        <label for="order" class="form-label">Ordem {{piepeline.order + 1}}</label>
                                      </div>
                                      <div class="col-12 mb-3">
                                        <label for="name" class="form-label">Nome</label>
                                        <input type="text" :class="['form-control', validateField(piepeline.name).status ? 'is-valid' : 'is-invalid']" id="name" v-model="piepeline.name" placeholder="Digite o nome" required>
<!--                                        <div class="invalid-feedback" v-show="!validateField(stage.name).status">{{validateField(stage.name).msg}}</div>-->
                                      </div>
                                      <div class="col-12 mb-3">
                                        <label for="sla" class="form-label">SLA em Dias</label>
                                        <input type="number" :class="['form-control', validateField(piepeline.sla_in_days).status ? 'is-valid' : 'is-invalid']" id="sla" v-model="piepeline.sla_in_days" placeholder="Digite o SLA" required>
<!--                                        <div class="invalid-feedback" v-show="!validateField(stage.sla_in_days).status">{{validateField(stage.sla_in_days).msg}}</div>-->
                                      </div>
                                      <div class="col-12 mb-3">
                                        <label for="probability" class="form-label">Probabilidade de Ganho em %</label>
                                        <input type="number" :class="['form-control', validateField(piepeline.probability).status ? 'is-valid' : 'is-invalid']" id="probability" v-model="piepeline.probability" placeholder="Digite a Probabilidade" required>
<!--                                        <div class="invalid-feedback" v-show="!validateField(stage.probability).status">{{validateField(stage.probability).msg}}</div>-->
                                      </div>
                                      <div class="col-12 mb-3">
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" role="switch" :id="`show_gain_${stage.id}`" @change="changeFilterPhase(stage.id, 'show_gain')" :checked="stage.filters && stage.filters.includes('show_gain')">
                                          <label class="form-check-label" :for="`show_gain_${stage.id}`">Exibir negócios com ganho</label>
                                        </div>
                                        <div class="form-check form-switch">
                                          <input class="form-check-input" type="checkbox" role="switch" :id="`show_loss_${stage.id}`" @change="changeFilterPhase(stage.id, 'show_loss')" :checked="stage.filters && stage.filters.includes('show_loss')">
                                          <label class="form-check-label" :for="`show_loss_${stage.id}`">Exibir negócios com perda</label>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="card-footer border-top-dashed">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0">
                                            <button class="btn btn-soft-danger" @click.prevent="deletePhase(piepeline)">
                                              <i class="ri-delete-bin-2-line"></i> Eliminar Etapa
                                            </button>
                                        </div>
                                    </div>
                                </div><!--end card-body-->
                            </div><!--end card-->
                        </draggable>
                    </div><!--end tasks-->
                </div>
            </div><!--end tasks-list-->
        </div>
        <!-- Vertically Centered -->
        <b-modal v-model="showAttendants" hide-footer class="v-modal-custom" title="Atendentes do Pipeline" centered>
            <div class="mx-n4 px-4" data-simplebar style="max-height: 225px;">
                <div class="vstack gap-3">
                    <div class="d-flex align-items-center" v-for="(user, key) in users" :key="'user-' + key">
                        <div class="avatar-xs flex-shrink-0 me-3">
                            <img :src="user.avatar" alt="" class="img-fluid rounded-circle">
                        </div>
                        <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">{{user.name}}</a></h5>
                        </div>
                        <div class="flex-shrink-0">
                            <button v-if="user.isLoading" class="btn btn-warning btn-load btn-sm">
                              <span class="d-flex align-items-center">
                                <span class="spinner-border flex-shrink-0" role="status">
                                  <span class="visually-hidden">Aguarde...</span>
                                </span>
                                <span class="flex-grow-1 ms-2">Aguarde...</span>
                              </span>
                            </button>
                            <button v-if="user.pipelineAttendant && !user.isLoading" type="button" class="btn btn-danger btn-sm" @click="removeAttendant(user)">Remover</button>
                            <button v-if="!user.pipelineAttendant && !user.isLoading" type="button" class="btn btn-light btn-sm" @click="addAttendant(user)">Atribuir</button>
                        </div>
                    </div>
                    <!-- end member item -->
                </div>
                <!-- end list -->
            </div>
        </b-modal>
    </Layout>
</template>
<style scoped>
</style>
